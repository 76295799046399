@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,800;1,400&display=swap');
body{
  background-color: #71AFA8;
  font-family: 'Barlow Condensed', sans-serif;
}
.container{
  display: flex;
  flex-wrap: wrap;
}
.left_container{
  flex: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.right_container {
  flex: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100vh;
}

.heading_website{
  border-top:  5px dashed #3E3737;
  border-bottom:  5px dashed #3E3737;
  
}
.website_header{
margin: 0;
font-style: normal;
font-weight: 800;
font-size:  8.090185676392572vh;
line-height:  8.753315649867375vh;
letter-spacing: 0.2em;
color: #3E3737;
/* identical to box height */

letter-spacing: 0.2em;

color: #3E3737;
}
.center_container{
width: 90%;
}
.center_container h1{
  margin: 0;
  text-align: center;
}

.coming_text{
  font-style: normal;
  font-weight: 800;
  font-size:  13.26259946949602vh;
  line-height: 14.323607427055704vh;
  color: #FFFFFF;
}
.soon_text{
font-style: normal;
font-weight: 800;
font-size: 26.923076923076923vh;
line-height: 19.893899204244033vh;
color: #FFFFFF;
margin-bottom: 10vh;
}
.your_text{
margin-top: 10px!important;
color: #3E3737;
font-weight: 800;
font-size:  5vh;
line-height: 84.2%;
text-align: center;
letter-spacing: 0.2em;
}
.superhero_text{
font-size: 9vh;
line-height: 84.2%;
text-align: center;
letter-spacing: 0.5em;
color: #B9E4FF;
}
.lower_website{
  border-top:  5px dashed #3E3737;
  border-bottom:  5px dashed #3E3737;
  padding: 1%;
  margin-top: 2vh;
  height: 15.915119363395226vh!important;
}
.form_container{
  margin-top: 20px!important;
  display: flex;
  justify-content: center;
  align-items: center ;
}
.form {
  width: 100%;
  position: relative;
} 
.input {
  position: relative;
  width: 70%;
  outline: none;
  border: none;
  padding-left:5px;
  font-size: 19px;
  line-height: 23px;
  color: #959595;
  height: 6.23342175066313vh;
  background: rgba(255, 255, 255, 0.53);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.cover{
  position: absolute;
  bottom: 0;
  min-width:50vw;
  z-index: 1;
 height:100%;
  right: 0;
}
.super_hero_images{
 height: 70vh;
}
.links{
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  right: 5vw;
  top:25vh;
  height: 20vh;
}
.links a{
  color: #71AFA8;
}

@media (max-width: 900px) {
  .flex-item-right, .flex-item-left {
    flex: 100%;
  }
  .right_container{
    max-height: 70vh;
  }
  .super_hero_images{
    max-height: 50vh;
  }
  .cover{
    max-height: 75vh;
    min-width: 50vw;
  }
  .heading_website{
    width: 80%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .website_header{
    font-size: 78px;
    line-height: 48px;
  }
  .coming_text{
    font-weight: 800;
    font-size: 56px;
    line-height: 61px;
  }
  .soon_text{
    font-weight: 800;
  font-size: 120px;
  line-height: 130px;
  }
  .center_container{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    }
   .left_container{
     padding-top: 10vh;
     justify-content: start;
     height: 80vh;
   }
   .form{
     width: 80vw;
   }
   .lower_website{
    height: 22vh!important;
     width: 70vw;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
   }
   .superhero_text{
     margin-top: 3vh!important;
    font-size: 70px;
    line-height: 84.2%;
    text-align: center;
    letter-spacing: 0.2em;
   }
   .your_text{
     margin: 0!important;
     padding: 0;
     font-size: 48px;
   }
}
@media (max-width: 590px){
  .cover{
    max-height: 60vh;
  }
  .heading_website{
    width: 80%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .website_header{
    font-size: 60px;
    line-height: 48px;
  }
  .coming_text{
    font-weight: 800;
    font-size: 56px;
    line-height: 61px;
  }
  .soon_text{
    font-weight: 800;
  font-size: 120px;
  line-height: 130px;
  }
  .center_container{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    }
   .left_container{
     padding-top: 10vh;
     justify-content: start;
     height: 80vh;
   }
   .form{
     width: 80vw;
   }
   .lower_website{
     width: 70vw;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
   }
   .superhero_text{
    font-size: 60px;
    line-height: 84.2%;
    text-align: center;
    letter-spacing: 0.2em;
   }
   .your_text{
     font-size: 30px;
   }
   .notify_me{
     font-size: 10px!important;
   }
}
@media (max-width: 430px){
 
  .superhero_text{
    font-size: 48px;
  }
}
@media (max-width: 325px){
  
  .cover{
    max-height: 60vh;
  }
  .heading_website{
    width: 80%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .website_header{
    font-size: 50px;
    line-height: 48px;
  }
  .coming_text{
    font-weight: 800;
    font-size: 56px;
    line-height: 61px;
  }
  .soon_text{
    font-weight: 800;
  font-size: 120px;
  line-height: 130px;
  }
  .center_container{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    }
   .left_container{
     padding-top: 10vh;
     justify-content: start;
     height: 90vh;
   }
   .form{
     width: 80vw;
   }
   .lower_website{
 
     width: 70vw;
     display: flex;
     justify-content: space-around;
     align-items: center;
     flex-direction: column;
     height: 20vh!important;
   }
   .superhero_text{
    font-size: 40px;
    line-height: 84.2%;
    text-align: center;
    letter-spacing: 0.2em;
   }
   .your_text{
     font-size: 30px;
   }
  
}